<template>
  <div class="all"  @click="editbg">
    <div><img class="imges" src="../images/feduplogo.png" alt=""></div>
    <div class="about">
      账号：<el-input class="width" v-model="account" placeholder="请输入账号"></el-input>
      密码：<el-input class="width" placeholder="请输入密码" v-model="password" show-password></el-input>
      <slider-verify-code ref="child" v-model="isLock" @change="handlerLock"></slider-verify-code>
      <el-button style="margin-top: 10px;"  @click="doLogin()">登录</el-button>
    </div>
  </div>
</template>
<script>
 //import post from '../lib/requset'
 import ajax from '../lib/ajax'
//import axios from 'axios'
import config from '../lib/config'
import {encrypt} from '../lib/secret'
import sliderVerifyCode  from './slider-verify-code.vue'
export default {
  name: 'Login',
  components: {
    'slider-verify-code': sliderVerifyCode 
  },
  data(){
    return {
      account:'',
      password:'',
      form:{},
      isLock: false,
    }
  },
  methods: {
    doLogin(){
      let _this = this;
      if(_this.isLock){ 

        let account = encrypt(this.account);
        let password = encrypt(this.password);
        ajax.post(config.login,{account:account,password:password},(res)=>{
          res = JSON.parse(res);
            if(res.code == 200){
                localStorage.setItem("Authorization", res.data.api_token);
                localStorage.setItem("Iad", res.data.is_admin);
                _this.$router.push('/');
              }else{
                  _this.$message.error(res.message);
                  _this.isLock = false;
                  _this.$refs.child.init();
              }
        });


        // let account = encrypt(this.account);
        // let password = encrypt(this.password);
        //  post(config.login,{account:account,password:password})
        // .then(function (res) {
            //  if(res.code == 200){
            //     localStorage.setItem("Authorization", res.data.api_token);
            //     localStorage.setItem("Iad", res.data.is_admin);
            //     _this.$router.push('/');
            //   }else{
            //       _this.$message.error(res.message);
            //       _this.isLock = false;
            //       _this.$refs.child.init();
            //   }
        //   })
        // .catch(function (err) {
        //    _this.$message.error(err);
        //       _this.isLock = false;
        //       _this.$refs.child.init();
        // });
      }else{
        _this.$message.error('请先完成滑块验证！');
      }
    },
    editbg(){
      
    },
    handlerLock(data) {
      if(data){
        if(this.account == '' || this.password == ''){
           this.$message.error('账号密码不能为空！');
           this.isLock = false;
           this.$refs.child.init();
        }else{
           this.$message.success('验证成功');
        }
      }
    },
  }
}
</script>

<style scoped lang="scss">
.imges{
    width: 350PX;
    padding-top: 100px;
}
.all{
  width: 100%;
  height: 100%;
  background-repeat:no-repeat;
  background-size:100% 100%;
  -moz-background-size:100% 100%;
  background-image:url('../images/login.jpg')
}

.about{
  width: 250px;
  margin: auto;
  padding-top: 20px;
    color: black;
}
.width{
  width: 200px;
  margin-bottom:15px;
}
</style>